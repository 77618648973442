/* eslint-disable */
import React, { Component } from "react";
// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

class Disclaimer extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="Disclaimer" />
          <section className="normal-section content-page light-nav brown-nav">
            <div className="container">
              <div className="content-contain">
                <h1>Website Disclaimer</h1>
                <p>
                  <b>DISCLAIMER OF WARRANTIES:</b>
                </p>
                <p>
                  All content shown on the site is provided in good faith and
                  derived from information believed to be accurate at the time
                  of publication. Neither Koji Capital Pty Ltd, its Directors,
                  officers or any third parties provide any warranty or
                  guarantee as to the accuracy, timeliness, completeness or
                  suitability of the information and materials found or offered
                  on this website for any particular purpose. You acknowledge
                  that such information and materials may contain inaccuracies
                  or errors and we expressly exclude liability for any such
                  inaccuracies or errors to the fullest extent permitted by law.
                </p>
                <p>
                  Your use of any information or materials on this website is
                  entirely at your own risk, for which we shall not be liable.
                  Services and Content are provided to you "as is", and Koji
                  Capital Pty Ltd expressly disclaims all warranties of any
                  kind, either implied or express, including but not limited to
                  warranties of merchantability, fitness for a particular
                  purpose, and non-infringement. It shall be your own
                  responsibility to ensure that any information available
                  through this website meets your specific requirements. Koji
                  Capital Pty Ltd makes no warranty, either implied or express,
                  that any part of the use of Sustain Trust's Website, goods and
                  services will be uninterrupted, error-free, virus-free,
                  timely, secure, accurate, reliable, or of any quality, nor is
                  it warranted either implicitly or expressly that any content
                  is safe in any manner for download. You understand and agree
                  that Koji Capital Pty Ltd does not provides general or
                  personal advice of any kind and that any information obtained
                  via the Website may be used solely at your own risk, and that
                  Koji Capital Pty Ltd will not be held liable in any way.
                </p>
                <p>
                  <b>LIMITATION OF LIABILITY:</b>
                </p>
                <p>
                  You expressly understand and agree that Koji Capital Pty Ltd
                  will not be liable for any direct, indirect, special,
                  incidental, consequential or exemplary damages suffered by
                  you, including, but not limited to, damages for loss of
                  profits, goodwill, use, data or other intangible assets (even
                  where Koji Capital Pty Ltd has been advised of the possibility
                  of such damages), resulting from:
                </p>
                <ol type="a">
                  <li>the use of Services or the inability to use Services;</li>
                  <li>
                    the cost of obtaining substitute goods and/or services
                    resulting from any transaction entered into on/through
                    Services;
                  </li>
                  <li>
                    unauthorized access to or alteration of your data
                    transmissions;
                  </li>
                  <li>
                    statements by any third party or conduct of any third party
                    using Services; or
                  </li>
                  <li>any other matter relating to Services.</li>
                </ol>
                <p>
                  This applies except for damages resulting from our gross
                  negligence or willful misconduct. Notwithstanding, nothing in
                  these Terms of Use shall constitute a waiver or limitation of
                  your rights under any federal or state securities or consumer
                  laws.
                </p>
                <p>
                  <b>THIRD PARTY SITES:</b>
                </p>
                <p>
                  From time to time, this website may include links to other
                  websites. These links are provided for your convenience to
                  provide further information. They do not signify that we
                  endorse the website(s). We have no responsibility for the
                  content of linked website(s).
                </p>
                <p>
                  Koji Capital Pty Ltd is not responsible for the products and
                  services offered by or on third-party sites. Koji Capital Pty
                  Ltd makes no warranty, either implied or express, that any
                  part of the use of third party websites will be uninterrupted,
                  error-free, virus-free, timely, secure, accurate, reliable, or
                  of any quality, nor is it warranted either implicitly or
                  expressly that any content is safe in any manner for download.
                </p>
                <p>
                  <b>RESERVATION OF RIGHTS:</b>
                </p>
                <p>
                  Koji Capital Pty Ltd reserves all of its rights, including but
                  not limited to any and all copyrights, trademarks, patents,
                  trade secrets, and any other proprietary right that Koji
                  Capital Pty Ltd may have in respect to the Website, its
                  content, and goods and services that may be provided in
                  association with it. The use of any of Koji Capital Pty Ltd’s
                  rights and property herein requires Koji Capital Pty Ltd’s
                  prior written consent. By making the Website, goods and
                  services available to you, Koji Capital Pty Ltd is not
                  providing you with any implied or express licenses or rights,
                  and you will have no rights to make any commercial use of the
                  Website, goods or services without Koji Capital Pty Ltd’s
                  prior written consent. Unauthorized use of this website may
                  give rise to a claim for damages and/or be a criminal offence.
                </p>
                <p>
                  <b>COPYRIGHT PROTECTION:</b>
                </p>
                <p>
                  This website contains materials which are owned by or licensed
                  to us. This material includes, but is not limited to, the
                  design, layout, icons, look, appearance and graphics.
                  Reproduction is strictly prohibited. Both the Website and the
                  Reports are protected by Copyright and except where such use
                  constitutes fair dealing under intellectual property law, you
                  must not use, copy, reproduce, publish, store, alter or
                  transmit any part of the contents of this website. Nothing
                  contained on the website or reports should be construed as
                  granting, by implication, estoppel or otherwise, any license
                  or right to use any of our trade names, trademarks or service
                  marks without our express prior written consent. All rights
                  not expressly granted to you under these Terms of Use are
                  reserved by Koji Capital Pty Ltd. It is our policy to block
                  access to or remove material that we believe in good faith to
                  be copyrighted material, and to remove and discontinue service
                  to repeat offenders.
                </p>
                <p>
                  <b>USER OBLIGATIONS:</b>
                </p>
                <p>
                  You must not violate or attempt to violate the security of the
                  Website. You must not hack into the Website, Koji Capital Pty
                  Ltd’s computer systems or the computer systems of other users
                  of the Website.  You must not use the Website, intentionally
                  or unintentionally, to violate any applicable local, state,
                  national or international law. If you breach these Terms, Koji
                  Capital Pty Ltd may (at its option, and without limiting the
                  remedies available to Koji Capital Pty Ltd in any way)
                  terminate its agreement with you or bar you from accessing the
                  Website on a temporary or permanent basis. You agree to fully
                  compensate Koji Capital Pty Ltd and its officers, employees
                  and agents from and against reasonable liability, loss,
                  damage, costs and expense (including, without limitation,
                  reasonable legal expenses on a reasonable basis) and penalties
                  incurred or suffered by any of them arising out of:
                </p>
                <ol type="a">
                  <li>Your material breach of these Terms;</li>
                  <li>
                    Any misuse or use of Koji Capital Pty Ltd content in breach
                    of these Terms; and
                  </li>
                  <li>
                    Any act of fraud or willful misconduct by or on behalf of
                    you. The obligations to compensate for losses incurred by
                    Koji Capital Pty Ltd and its officers, employees and agents
                    in these Terms are continuing obligations of the parties,
                    separate and independent from their other obligations and
                    survive termination of these Terms and absolute and
                    unconditional and unaffected by anything that might have the
                    effect of prejudicing, releasing, discharging or affecting
                    in any other way the liability of the party that is obliged
                    to provide the compensation.
                  </li>
                </ol>
              </div>
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

export default Disclaimer;
